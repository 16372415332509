[data-sidebar-style="mini"],
[data-layout="horizontal"] {
    .nav-control {
        display: none;
    }
}

[data-sidebar-style="overlay"] {
    @include respond('phone-land') {
        .nav-header .logo-abbr {
            display: block;
        }
    }
}

[data-header-position="fixed"] {
    .nav-header {
        position: fixed;
    }
}

.nav-header {
    height: 6rem;
    width: 20.5rem;
    display: inline-block;
    text-align: left;
    position: absolute;
    top: 0;
    background-color: var(--nav-headbg);
    transition: all .2s ease;
    z-index: 5;

    .logo-abbr {
        max-width: 2.9375rem;

        @include respond('laptop') {
            max-width: 2.8125rem;
        }

        @include respond('phone') {
            width: 2.1875rem;
            height: 2.1875rem;
        }
    }

    .logo-compact {
        display: none;
    }

    @include respond ('laptop') {
        height: 5.5rem;
        width: 17rem;
    }

    .brand-logo {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.125rem;
        color: $white;
        text-decoration: none;
        padding-left: 1.875rem;
        padding-right: 1.875rem;
        font-weight: 700;

        @include respond('laptop') {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }

        [data-sidebar-style="compact"] &,
        [data-sidebar-style="mini"] & {
            padding-left: 0;
            padding-right: 0;
            justify-content: center;
        }

        @include respond('phone-land') {
            padding-left: 0;
            padding-right: 0;
            justify-content: center;
        }
    }

    .brand-title {
        font-weight: 600;
        font-size: 1.5rem;
        color: #4E3F6B;
        margin: 30px 15px;
    }

    @include respond ('phone-land') {
        top: 0;
        background: transparent;
    }

    .rect-primary-rect {
        fill: var(--primary);
    }
}

@include respond('tab-land') {
    .nav-header {
        height: 5rem;
    }
}

@include custommq($max: 63.9375rem) {
    .nav-header {
        width: 5rem;

        .brand-title {
            display: none;
        }
    }
}

.nav-control {
    cursor: pointer;
    position: absolute;
    right: -5rem;
    text-align: center;
    top: 55%;
    transform: translateY(-50%);
    z-index: 9999;
    font-size: 1.4rem;
    padding: 0.125rem 0.5rem 0;
    border-radius: 0.125rem;

    @include respond('laptop') {
        right: -3.556rem;
    }

    @include respond('phone-land') {
        right: -3rem;
    }

    @include respond('phone') {
        right: -3rem;
    }
}

////////////////////
// Humbuger Icon
////////////////////
.hamburger {
    display: inline-block;
    left: 0rem;
    position: relative;
    top: 0rem;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    width: 1.625rem;
    z-index: 999;
}

.hamburger .line {
    background: #717579;
    display: block;
    height: 0.1875rem;
    border-radius: 0.1875rem;
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
    margin-right: auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.hamburger .line:nth-child(1) {
    width: 1.625rem;
}

.hamburger .line:nth-child(2) {
    width: 1.625rem;
}

.hamburger .line:nth-child(3) {
    width: 0.9375rem;
}

.hamburger:hover {
    cursor: pointer;
}

.hamburger:hover .line {
    width: 1.625rem;
}

.hamburger.is-active .line:nth-child(1),
.hamburger.is-active .line:nth-child(3) {
    width: 0.625rem;
    height: 0.125rem;
}

.hamburger.is-active .line:nth-child(2) {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    width: 1.375rem;
    height: 0.125rem;
}

.hamburger.is-active .line:nth-child(1) {
    -webkit-transform: translateY(4px) translateX(12px) rotate(45deg);
    transform: translateY(4px) translateX(12px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
    -webkit-transform: translateY(-4px) translateX(12px) rotate(-45deg);
    transform: translateY(-4px) translateX(12px) rotate(-45deg);
}

@media (min-width:47.9375rem) {
    [data-sidebar-style="compact"] {
        .nav-control {
            display: none;
        }

        .nav-header {
            width: 11.25rem;
        }
    }
}

[data-sidebar-style="full"][data-layout="vertical"] {
    .menu-toggle {
        .brand-title {
            display: none;
        }

        .nav-header {
            .logo-abbr {
                display: block;
            }
        }
    }
}

[data-sidebar-style="full"][data-layout="horizontal"] {
    .logo-compact {
        display: none;
    }
}

[data-sidebar-style="mini"] {
    .nav-header {
        height: 6.5rem;

        .logo-abbr {
            display: block;
        }

        @include custommq($max: 63.9375rem) {
            height: 5.5rem;
        }
    }
}

[data-sidebar-style="compact"][data-layout="vertical"] {
    .nav-header {
        .brand-title {
            display: none;
        }

        .logo-compact {
            max-width: 4.6875rem;
        }
    }
}

[data-sidebar-style="compact"][data-layout="horizontal"] {
    .nav-header .brand-logo {
        padding-left: 1.875rem;
        padding-right: 1.875rem;
        justify-content: start;
    }
}

[data-sidebar-style="modern"][data-layout="vertical"] {
    .nav-header {
        width: 10.625rem;

        .brand-title {
            display: none;
        }

        .logo-compact {
            display: none;
        }
    }
}