.file-card {
    .file-inputs {
        width: fit-content;
        position: relative;
        height: 55px;
        margin-top: 10px;
        input {
            position: relative;
            text-align: right;
            opacity: 0;
            z-index: 0;
            cursor: pointer;
            height: 55px;
            max-width: 200px;
        }
        button {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            z-index: -1;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            background-color: var(--primary);
            font-size: 1.1rem;
            cursor: pointer;
            border: none;
            outline: none;
            padding: 0.938rem 1.5rem;
            border-radius: 1.25rem;
            font-weight: 400;
            font-size: 1rem;
            // padding: 1em;
            transition: background-color 0.4s;
            i {
                width: 1.5em;
                height: 1.5em;
                padding: 0.4em;
                background-color: #fff;
                color: var(--primary);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 0.8em;
                font-size: 0.8em;
            }
        }
        &:hover {
            button {
                background-color: var(--primary-dark);
            }
        }
    }
}

.home_upload .file-card {
    .file-inputs {
        
        input{
            z-index: 2;
        }
        button{
            z-index: 1;
        }
    }
}

.main {
    font-weight: bold;
    margin-bottom: 0.4em;
}

.info {
    font-size: 0.8rem;
}