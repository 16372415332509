.nestable-item-name {
    color: #fff;
    background: #886CC0;
    display: block;
    height: 35px;
    margin: 5px 0;
    padding: 8px 10px;
    text-decoration: none;
    font-weight: 700;
    border: 1px solid #886CC0;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 13px;
    margin-bottom: -4px;
}

.nestable-icon {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
}

.nestable-icon:before {
    content: '-';
    display: inline-block;
    width: 0;
    height: 0;
    overflow: hidden;
}


/* 20x20 */

.icon-plus-gray {
    width: 20px;
    height: 20px;
}

.icon-minus-gray {
    width: 20px;
    height: 20px;
}

.nestable-item-icon {
    margin-right: 5px;
    cursor: pointer;
}