// Basic Input
.header__search {
    display: flex;
    padding: .40rem .75rem;
    background-color: #F8F8F8;
    border-radius: 10px;
}

.header__search .fas {
    padding-top: 11px;
}

.header__input {
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 8px;
}

.header__icon,
.header__toggle {
    font-size: 1.2rem;
}

.form-control {
    // border-radius: 0;
    background: $white;
    border: 1px solid #eee;
    // height: 2.8125rem;
    padding: 10px;
    color: $dark;
    border-radius: 4px;
    height: 38px;
    @include respond('laptop') {
        height: 38px;
    }
    &:hover,
    &:focus,
    &.active {
        box-shadow: none;
        background: $white;
        color: $dark;
    }
    &:focus {
        border-color: var(--primary);
    }
    &.solid {
        background: $body-bg;
        border-color: #ececec;
        color: #000;
        &[type="file"] {
            padding: 6px 10px;
        }
    }
}

.input-rounded {
    border-radius: 6.25rem;
}

// Input Color
[data-theme-version="light"],
[data-theme-version="dark"] {
    .input-primary {
        .form-control {
            border-color: var(--primary);
        }
        .input-group-text {
            background-color: var(--primary);
            color: $white;
        }
    }
    .input-danger {
        .form-control {
            border-color: $danger;
        }
        .input-group-text {
            background-color: $danger;
            color: $white;
        }
    }
    .input-info {
        .form-control {
            border-color: $info;
        }
        .input-group-text {
            background-color: $info;
            color: $white;
        }
    }
    .input-success {
        .form-control {
            border-color: $success;
        }
        .input-group-text {
            background-color: $success;
            color: $white;
        }
    }
    .input-warning {
        .form-control {
            border-color: $warning;
        }
        .input-group-text {
            background-color: $warning;
            color: $white;
        }
    }
}

// Input Outline
[data-theme-version="light"],
[data-theme-version="dark"] {
    .input-primary-o {
        .form-control {
            border-color: var(--primary);
        }
        .input-group-text {
            background-color: transparent;
            border-color: var(--primary);
            color: var(--primary);
        }
    }
    .input-danger-o {
        .form-control {
            border-color: $danger;
        }
        .input-group-text {
            background-color: transparent;
            border-color: $danger;
            color: $danger;
        }
    }
    .input-info-o {
        .form-control {
            border-color: $info;
        }
        .input-group-text {
            background-color: transparent;
            border-color: $info;
            color: $info;
        }
    }
    .input-success-o {
        .form-control {
            border-color: $success;
        }
        .input-group-text {
            background-color: transparent;
            border-color: $success;
            color: $success;
        }
    }
    .input-warning-o {
        .form-control {
            border-color: $warning;
        }
        .input-group-text {
            background-color: transparent;
            border-color: $warning;
            color: $warning;
        }
    }
}

// Input Size/
// .form-control{
// min-height: 2.5rem;
// }
// .form-control-sm{
//     min-height: 2.25rem;
// }
// .form-control-lg{
//     min-height: 3.25rem;
// }
// Input Group
.input-group-text {
    background: #d7dae3;
    border: 0.0625rem solid #f5f5f5;
    min-width: 3.125rem;
    display: flex;
    justify-content: center;
    padding: 0.532rem 0.75rem;
    i {
        font-size: 1rem;
    }
}

.form-file-label {
    height: 2.5rem;
    padding: 0.5rem 0.75rem;
}

.input-group-prepend .btn,
.input-group-append .btn {
    z-index: 0;
}

.custom-select {
    background: none;
    // height: 2.8125rem;
    border-color: $border;
    color: $dark;
    // border-radius: 0rem
    &:focus {
        box-shadow: none;
        border-color: var(--primary);
        color: var(--primary);
    }
}

.form-file-label {
    // height: 2.8125rem;
    // border-radius: 0rem
    background: #656C73;
    white-space: nowrap;
    color: $white;
    @at-root [data-theme-version="dark"] & {
        background: $d-border;
        border-color: $d-border;
        color: $body-color;
    }
}

.custom_file_input {
    .input-group-prepend,
    .input-group-append {
        // height: 2.8125rem;
    }
    .form-file-label::after {
        height: 100%;
        // line-height: 2.4;
    }
}

.form-control:disabled,
.form-control[readonly] {
    background: $white;
    opacity: 1;
}

.form-file {
    border: 0.0625rem solid $border;
    background: $white;
    @at-root [data-theme-version="dark"] & {
        background: $d-bg;
        border-color: $d-border;
    }
}

.input-group {
    >.form-control-plaintext,
    >.form-select,
    >.form-file {
        position: relative; // For focus state's z-index
        flex: 1 1 auto;
        // Add width 1% and flex-basis auto to ensure that button will not wrap out
        // the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
        width: 1%;
        margin-bottom: 0;
    }
    &>.form-file {
        display: flex;
        align-items: center;
        &:not(:last-child) .form-file-label,
        &:not(:last-child) .form-file-label::after {
            @include border-right-radius(0);
        }
        &:not(:first-child) .form-file-label {
            @include border-left-radius(0);
        }
    }
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    border-radius: $radius;
}

.form-file {
    .form-control {
        margin: 0;
        border-radius: 0;
        border: 0;
        height: auto;
    }
}

.custom-form-inner {
    display: flex;
}

.custom-form-wrapper {
    overflow-x: scroll;
    min-width: 100%;
}

.custom-form-label,
.custom-form-input,
.custom-form-row {
    border: 1px solid #e4e3e2;
    padding: 8px;
    min-width: 150px;
}

.custom-form-label {
    min-width: max-content;
}

.custom-form-label,
.custom-form-row {
    font-weight: 800;
    background-color: white;
    font-size: 1rem;
}

.custom-form-input {
    background-color: #f8f8f8;
}

.custom-form-input2 div {
    background-color: white;
    border: 1px solid #eee;
    padding: 9px;
    border-radius: 4px;
    min-height: 40px;
}

.custom-form-input input,
select {
    border: 1px solid #eee;
    padding: 9px;
    border-radius: 4px;
}

.custom-form-row {
    width: 100%;
}

.custom-form-cell {
    flex-grow: 1;
}

.custom-add-button {
    height: fit-content !important;
    border: none !important;
    vertical-align: inherit !important;
    min-height: 0 !important;
    padding: 0 !important;
}

input[type="file"] {
    padding: 0.375rem 0.75rem;
    height: auto;
}