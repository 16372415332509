//heart Blast Effect
.heart-blast {
    background-position: -1680px 0!important;
    transition: background 1s steps(28);
}

.heart {
    width: 60px;
    height: 60px;
    display: inline-block;
    cursor: pointer;
    margin: -25px -15px;
}

.header-profile {
    position: relative;
    padding-left: 1.875rem;
    .nav-link {
        position: relative;
        background: $white;
        border-radius: 3rem;
        padding: 0.1875rem;
        img {
            height: 3.75rem;
            width: 3.75rem;
            border-radius: 4.25rem;
            @include respond('laptop') {
                height: 2.1875rem;
                width: 2.1875rem;
            }
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -0.1875rem;
            border-radius: inherit;
            background: linear-gradient(to right, #CEA7FF, rgba(255, 167, 215, 1));
        }
    }
}

.header-profile {
    .nav-link {
        padding-right: 0!important;
        padding-left: 0!important;
    }
}

.tryal-gradient {
    background: var(--primary);
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,0.52+100 */
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.52) 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.52) 100%);
        /* Chrome10-25,Safari5.1-6 */
        /* IE6-9 */
        border-radius: 1.25rem;
    }
}

.tryal {
    padding: 3rem;
    h2 {
        font-size: 2rem;
        font-weight: 700;
        color: $white;
        position: relative;
        z-index: 1;
    }
    span {
        font-size: 1rem;
        font-weight: 400;
        color: $white;
        display: block;
        margin: 1.875rem 0;
        position: relative;
        z-index: 1;
    }
    a {
        font-weight: 500!important;
        background: $white;
        color: $black!important;
        position: relative;
        z-index: 1;
    }
}

.project-tab {
    .card-tabs {
        .nav-tabs {
            border: 0;
            background: var(--rgba-primary-1);
            border-radius: 3.125rem;
            color: #717579;
            font-size: 0.875rem;
            font-weight: 400;
            .nav-link {
                border: 0;
                font-size: 1rem;
                font-weight: 600;
                background: none;
                padding: 0.938rem 1.563rem;
                border-radius: $radius;
                &.active {
                    background: $white;
                    color: $black!important;
                    border-radius: 3.125rem;
                }
                @include respond ('phone') {
                    padding: 0.938rem 1.25rem;
                }
            }
        }
    }
}

.on-going {
    background: linear-gradient(180deg, #FFA26D 0%, #FFCF6D 100%);
}

.Unfinished {}

.sd-shape {
    animation: movedelement 10s linear infinite;
    width: 100%;
    transform: scale(1.1);
    position: relative;
    z-index: 1;
}

@keyframes movedelement {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    25% {
        -webkit-transform: translate(10px, 10px);
        transform: translate(10px, 10px);
    }
    50% {
        -webkit-transform: translate(5px, 5px);
        transform: translate(5px, 5px);
    }
    75% {
        -webkit-transform: translate(10px, -5px);
        transform: translate(10px, -5px);
    }
    to {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}

.profile-k {
    span {
        height: 3.125rem;
        width: 3.125rem;
        line-height: 3.125rem;
        display: block;
        font-size: 1.5rem;
        font-weight: 700;
        color: white;
        border-radius: 3.125rem;
        text-align: center;
    }
    img {
        height: 3.125rem;
        width: 3.125rem;
        border-radius: 3.125rem;
        object-fit: cover;
    }
}

.email-check {
    .like-btn {
        display: inline-block;
        padding-left: 0;
        margin-bottom: 0.75rem;
        cursor: pointer;
        font-size: 1.375rem;
        font-family: 'Font Awesome 5 Free';
        input {
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            display: block;
        }
    }
    .like-btn input:checked~.checkmark {
        background-color: var(--primary);
        &:before {
            color: #fff;
        }
    }
    .checkmark {
        height: 3.125rem;
        text-align: center;
        line-height: 3.125rem;
        width: 3.125rem;
        display: block;
        border: 0.0625rem solid var(--primary);
        border-radius: 0.875rem;
        &:before {
            width: 100%;
            height: 100%;
            color: var(--primary);
            left: 0;
            top: 0;
            border-radius: 0.875rem;
            padding: 0.25rem 0.375rem;
        }
    }
    .like-btn .checkmark:before {
        content: "\f08d";
        font-size: 1.25rem;
        font-weight: 900;
    }
}

.final-badge {
    .badge {
        padding: 0.75rem 1.625rem;
        font-size: 0.875rem;
        font-weight: 400;
        color: var(--primary) !important;
        border-radius: 3.125rem;
        margin-right: 0.5rem;
        margin-top: 1.5rem;
        cursor: pointer;
        i {
            transform: scale(1.5);
        }
    }
}

.recent-emails {
    border-bottom: 0.0625rem solid var(--rgba-primary-1);
    padding: 0.9375rem 1.5625rem;
}

.default-progress {
    height: 0.625rem;
}

.card-slider {
    .owl-nav {
        display: flex;
        margin-top: 1.875rem;
        .owl-prev,
        .owl-next {
            height: 2.3125rem;
            width: 2.3125rem;
            background: #D7D7D7;
            text-align: center;
            line-height: 2.3125rem;
            color: white;
            border-radius: 3.125rem;
            &:hover {
                background: #717579;
            }
        }
        .owl-next {
            margin-left: 3.5rem;
        }
        .owl-prev {
            bottom: 0;
            position: absolute;
            left: '0';
        }
    }
}

.redial {
    margin-top: -1.5rem;
}

.projects {
    margin-top: 1.25rem;
    .badge {
        font-size: 0.875rem;
        font-weight: 400;
        padding: 0.625rem 0.75rem;
    }
}

.project-details {
    margin: 0.625rem 0;
    .big-wind {
        height: 68px;
        width: 68px;
        border: 1px solid #D7D7D7;
        border-radius: 14px;
        line-height: 68px;
        text-align: center;
    }
}

.msg-bx {
    border-bottom: 0.0625rem solid var(--rgba-primary-1);
    padding: 1.875rem 1.25rem;
    position: relative;
    .msg {
        .image-box {
            height: 3.125rem;
            width: 3.125rem;
            position: relative;
            img {
                height: 100%;
                width: 100%;
                border-radius: 3.125rem;
                object-fit: cover;
            }
            &.active {
                &:after {
                    height: 1rem;
                    width: 1rem;
                    border-radius: 3.125rem;
                    background: $success;
                    border: 0.0625rem solid $white;
                    bottom: 0;
                    right: -0.3125rem;
                    position: absolute;
                    content: "";
                }
            }
        }
    }
}

.side-bar-profile {
    padding: 0 40px;
    margin: 30px 0px;
    .side-bar-profile-img {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -0.1875rem;
            border-radius: inherit;
            background: linear-gradient(to right, #CEA7FF, #ffa7d7);
            height: 56px;
            width: 56px;
            border-radius: 50px;
        }
        img {
            width: 50px;
            height: 50px;
            border-radius: 50px;
            margin-right: -18px;
        }
    }
    @include custommq ($max:100rem) {
        padding: 0 24px;
        .side-bar-profile-img {
            img {
                margin-right: 13px;
            }
        }
    }
    @include respond ('laptop') {
        display: none;
    }
}

.bg-gradientf {
    background: linear-gradient(90deg, #FFA26D 50%, #FFDF6D 102.88%);
}

.logo-abbr {
    path {
        fill: var(--primary);
    }
}

.ck.ck-editor__editable_inline>:first-child {
    height: 150px;
}

.textarea_editor {
    height: 120px;
}

.image-placeholder {
    position: relative;
    max-width: 130px;
    margin: 10px auto;
    .avatar-edit {
        position: absolute;
        right: 12px;
        z-index: 1;
        top: 10px;
        input {
            display: none;
            +label {
                display: inline-block;
                width: 34px;
                height: 34px;
                margin-bottom: 0;
                border-radius: 100%;
                background: #FFFFFF;
                border: 1px solid transparent;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                cursor: pointer;
                font-weight: normal;
                transition: all .2s ease-in-out;
                &:hover {
                    background: #f1f1f1;
                    border-color: #d6d6d6;
                }
                &:after {
                    content: "\f303";
                    font-family: 'Font Awesome 5 Free';
                    font-weight: 900;
                    font-size: 14px;
                    color: #757575;
                    position: absolute;
                    top: 4px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: auto;
                }
            }
        }
    }
    .avatar-preview {
        width: 120px;
        height: 120px;
        position: relative;
        border-radius: 100%;
        border: 6px solid #F8F8F8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        &>div {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            overflow: hidden;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover
            }
        }
    }
}

.kanban-bx .react-kanban-column {
    width: 360px;
    min-width: 360px;
    -webkit-flex-grow: unset;
    flex-grow: unset;
    -webkit-flex-basis: unset;
    flex-basis: unset;
    padding-right: 15px;
    padding-left: 15px;
}